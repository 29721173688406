<template>
  <div class="min-width relative">
    <span class="absolute sm:top-3 top-1/4 left-3 z-10">
      <FontAwesomeIcon class="h-5 w-5 text-gray-400 text-xl" :icon="['far', 'magnifying-glass']" />
    </span>
    <input
      v-model="input"
      placeholder="Enter search"
      @input="debounceInput()"
      class="w-100 border border-gray-300 rounded-lg text-base py-2.5 pl-10 pr-3"
    />
    <span class="absolute top-3 right-3 cursor-pointer z-10" v-if="input" @click="() => (input = '')">
      <FontAwesomeIcon class="h-5 w-5 text-gray-400 hover:text-gray-600 text-xl" :icon="['far', 'circle-xmark']" />
    </span>
  </div>
</template>

<script>
import { debounce } from 'lodash';

export default {
  name: 'GenericSearch',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      input: this.value
    };
  },
  watch: {
    input() {
      this.debounceInput();
    }
  },
  methods: {
    debounceInput: debounce(async function () {
      await this.$emit('updateSearch', this.input);
    }, 500)
  }
};
</script>

<style>
.min-width {
  min-width: 20rem;
}
</style>
